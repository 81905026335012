import { axiosGet, axiosDelete, axiosPost, axiosPatch } from './rest'
import { Asset, AssetForWO} from './model'
import { Dispatch } from 'redux'
import {
    AddAssetSuccessEvent,
    DeleteAssetSuccessEvent,
    GetAllAssetSuccessEvent,
    UpdateAssetSuccessEvent
} from './state-event'

import {axiosGet as axiosGetWO, axiosPost as axiosPostWO, axiosDelete as axiosDeleteWO} from '../../rest'

// export const updateAssetContent = async (patch: assetUpdateContent) => {
//     try {
//         // const res = await axiosPatch<assetUpdateContent>(`/api/workorder/${id}`, patch)
//         const res = await axiosPatch<assetUpdateContent>('/api/workorder/', patch)
//         return res.data.data!!
//     } catch (error) {
//         console.log(error)
//         throw error
//     }
// }

export async function getLocation(value: string) {
    try {
        const res = await axiosGet<any>(`/api/asset/search?location=${value}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function addDataTicketAffectedAsset(data) {
    try {
        const res = await axiosPost<any>('/api/ticketAffectedAsset', data)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function searchAssetSerial(Serial: string) {
    try {
        const res = await axiosGet<any>(`/api/asset/serial/${Serial}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getAssetById(id: string): Promise<Asset> {
    try {
        const res = await axiosGet<Asset>(`/api/asset/${id}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAllAsset = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Asset[]>('/api/asset')
            dispatch(GetAllAssetSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addAsset = (businessHour: Asset) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Asset>('/api/asset', businessHour)
            dispatch(AddAssetSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteAsset = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/asset${id}`)
            dispatch(DeleteAssetSuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// edit function
export const updateAsset = (patch: Asset) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            // delete patch.id
            const response = await axiosPatch<Asset>(`/api/asset/${id}`, patch)
            dispatch(UpdateAssetSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export async function addAssetWithWO(asset): Promise<any> {
    try {
        const response = await axiosPostWO<AssetForWO>('/api/relatedAsset/create', asset)
        // dispatch(AddAssetSuccessEvent.build(response.data.data!!))
        return response
    } catch (err) {
        console.log(err)
        throw err
    }
}

export async function getAssetForWOByWOId(id: string): Promise<AssetForWO[]> {
    try {
        const res = await axiosGetWO<AssetForWO[]>(`/api/relatedAsset/workorder/${id}`)
        return res.data.data!! || []
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function DeleteAssetForWOByTicketRelatedAssetId(id: string): Promise<any> {
    try {
        const res = await axiosDeleteWO<any>(`/api/relatedAsset/${id}`)
        return res.status
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getAssetForWOOpenFromTicket(id: string): Promise<AssetForWO[]> {
    try {
        const res = await axiosGet<AssetForWO[]>(`/api/ticketAffectedAsset/ticketId/${id}`)
        return res.data.data!! || []
    } catch (error) {
        console.log(error)
        throw error
    }
}

